import { configureStore } from "@reduxjs/toolkit"
import { Action } from "redux"
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from "redux-persist"
import storage from "redux-persist/es/storage"
import { ThunkMiddleware, thunk } from "redux-thunk"
import { appName } from "../config"
import reducers, { StoreState } from "./reducers"


const persistConfig = {
    key: appName,
    storage: storage,
    whitelist: ['authUser'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const thunkMiddleware: ThunkMiddleware<StoreState, Action> = thunk

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                warnAfter: 500,
            },
            immutableCheck: {
                warnAfter: 500,
            }
        })

        middlewares.push(thunkMiddleware)

        return middlewares
    },
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch

// export const persistor = persistStore(store)