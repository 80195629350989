import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export interface CustomerType {
    id?: string
    name: string
    address: string
    phone: string
    phone2: string
    tag: string
    status: string
    assignedTo: string
    position: string
    email: string
    city: string
    state: string
    country: string
    zipcode: number
    description: string
    latitude?: number,
    longitude?: number,
}

export const CustomersampleData = [
    {
        id: "1",
        name: "Arun Kumar",
        address: "12 Gandhi St",
        phone: "9840167890",
        phone2: "9840265432",
        tag: "Customer",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Manager",
        email: "arun.kumar@example.com",
        city: "Chennai",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 600001,
        latitude: 13.0827,
        longitude: 80.2707,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
        id: "2",
        name: "Priya Sharma",
        address: "34 Anna Salai",
        phone: "9840355555",
        phone2: "",
        tag: "Customer",
        status: "Active",
        assignedTo: "Marketing Team",
        position: "Coordinator",
        email: "priya.sharma@example.com",
        city: "Chennai",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 600002,
        latitude: 13.0604,
        longitude: 80.2496,
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
        id: "3",
        name: "Vijay Raghavan",
        address: "56 Mount Road",
        phone: "9840411122",
        phone2: "9840533344",
        tag: "Prospect",
        status: "Inactive",
        assignedTo: "Support Team",
        position: "Developer",
        email: "vijay.raghavan@example.com",
        city: "Trichy",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 620001,
        latitude: 10.8171,
        longitude: 78.6968,
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: "4",
        name: "Lakshmi Narayan",
        address: "78 Mylapore",
        phone: "9840677788",
        phone2: "",
        tag: "Customer",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Sales Representative",
        email: "lakshmi.narayan@example.com",
        city: "Chennai",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 600004,
        latitude: 13.0346,
        longitude: 80.2621,
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
        id: "5",
        name: "Karthik Subramaniam",
        address: "123 Cauvery St",
        phone: "9840799999",
        phone2: "",
        tag: "Customer",
        status: "Inactive",
        assignedTo: "Marketing Team",
        position: "Analyst",
        email: "karthik.subramaniam@example.com",
        city: "Tanjore",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 613001,
        latitude: 10.7905,
        longitude: 79.1378,
        description: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: "6",
        name: "Suresh Iyer",
        address: "456 Raja Street",
        phone: "9840823456",
        phone2: "9840987654",
        tag: "Customer",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Manager",
        email: "suresh.iyer@example.com",
        city: "Trichy",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 620002,
        latitude: 10.8217,
        longitude: 78.6894,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
        id: "7",
        name: "Meena Ramesh",
        address: "789 Market Road",
        phone: "9841034567",
        phone2: "",
        tag: "Customer",
        status: "Active",
        assignedTo: "Marketing Team",
        position: "Coordinator",
        email: "meena.ramesh@example.com",
        city: "Tanjore",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 613002,
        latitude: 10.7823,
        longitude: 79.1311,
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
        id: "8",
        name: "Rajesh Pillai",
        address: "101 Temple Street",
        phone: "9841145678",
        phone2: "9841209876",
        tag: "Prospect",
        status: "Inactive",
        assignedTo: "Support Team",
        position: "Developer",
        email: "rajesh.pillai@example.com",
        city: "Trichy",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 620003,
        latitude: 10.8050,
        longitude: 78.6968,
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: "9",
        name: "Anita Nair",
        address: "234 MG Road",
        phone: "9841356789",
        phone2: "",
        tag: "Customer",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Sales Representative",
        email: "anita.nair@example.com",
        city: "Chennai",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 600005,
        latitude: 13.0383,
        longitude: 80.2617,
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
        id: "10",
        name: "Manoj Srinivasan",
        address: "567 Park Avenue",
        phone: "9841467890",
        phone2: "",
        tag: "Customer",
        status: "Inactive",
        assignedTo: "Marketing Team",
        position: "Analyst",
        email: "manoj.srinivasan@example.com",
        city: "Tanjore",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 613003,
        latitude: 10.7991,
        longitude: 79.1334,
        description: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    }
];


export enum CustomerActionsList {
    ADD_CUSTOEMER = 'ADD_CUSTOEMER',
    EDIT_CUSTOEMER = 'EDIT_CUSTOEMER',
    FETCH_CUSTOEMER = 'FETCH_CUSTOEMER',
    DELETE_CUSTOEMER = 'DELETE_CUSTOEMER',
    EDIT_STATUS = 'EDIT_STATUS'
}

export interface DeleteCustomer {
    id: string
}

export interface AddCustomerAction {
    [key: string]: any;
    type: CustomerActionsList.ADD_CUSTOEMER
    data: CustomerType
}

export interface EditCustomerAction {
    [key: string]: any;
    type: CustomerActionsList.EDIT_CUSTOEMER
    data: CustomerType
    id: string
}

export interface EditStatusAction {
    [key: string]: any;
    type: CustomerActionsList.EDIT_STATUS
    data: string
    id: string
}

export interface FetchCustomerAction {
    [key: string]: any;
    type: CustomerActionsList.FETCH_CUSTOEMER
    // data: Customer[]
}

export interface DeleteCustomerAction {
    [key: string]: any;
    type: CustomerActionsList.DELETE_CUSTOEMER
    data: DeleteCustomer
}

export type CustomerActions = AddCustomerAction | EditCustomerAction | FetchCustomerAction | DeleteCustomerAction | EditStatusAction

export const addCustomer = (data: CustomerType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddCustomerAction>({
            type: CustomerActionsList.ADD_CUSTOEMER,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editCustomer = (data: CustomerType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditCustomerAction>({
            type: CustomerActionsList.EDIT_CUSTOEMER,
            data: data,
            id: id
        })
    }
}

export const editStatus = (status: string, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditStatusAction>({
            type: CustomerActionsList.EDIT_STATUS,
            data: status,
            id: id
        })
    }
}

export const fetchCustomer = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchCustomerAction>({
            type: CustomerActionsList.FETCH_CUSTOEMER
            // data: response.data
        })
    }
}

export const deleteCustomer = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteCustomerAction>({
            type: CustomerActionsList.DELETE_CUSTOEMER,
            data: { id }
        })
    }
}