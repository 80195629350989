import { DepartmentActionsList, DepartmentSampleData, DepartmentType } from "../actions/departmentActions";
import { Action } from "../actions/types";


export const departmentReducer = (state: DepartmentType[] = DepartmentSampleData, action: Action) => {

    switch (action.type) {

        case DepartmentActionsList.ADD_DEPARTMENT: {

            return [...state, action.data]
        }

        case DepartmentActionsList.EDIT_DEPARTMENT: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case DepartmentActionsList.FETCH_DEPARTMENT: {

            return state
        }

        case DepartmentActionsList.DELETE_DEPARTMENT: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}