import { StaffActionsList, StaffsampleData, StaffType } from "../actions/staffActions";
import { Action } from "../actions/types";


export const staffReducer = (state: StaffType[] = StaffsampleData, action: Action) => {

    switch (action.type) {

        case StaffActionsList.ADD_STAFF: {

            return [...state, action.data]
        }

        case StaffActionsList.EDIT_STAFF: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case StaffActionsList.EDIT_STATUS: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...newState[index],
                    id: action.id,
                    status: action.data
                }
            }
            return newState
        }

        case StaffActionsList.FETCH_STAFF: {

            return state
        }

        case StaffActionsList.DELETE_STAFF: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}