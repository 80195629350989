// import Card from '@mui/material/Card';
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import * as Feather from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../components/PageLayout";
import PageTitle from "../components/PageTitle";
import Table from "../components/Table";
import CollapseCard from "../components/custom/CollapseCard";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { addCategory, CategoryType, deleteCategory, editCategory } from "../redux/actions/categoryActions";
import { StoreState } from "../redux/reducers";
import { AppDispatch } from "../redux/store";

const Category = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const CategoryList = useSelector<StoreState, CategoryType[]>(state => state.category)

    const [editFlag, setEditFlag] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [department, setDepartment] = useState<string>('');
    const [departmentError, setDepartmentError] = useState<string>('');
    const [id, setId] = useState<string>('0');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const clearHandler = () => {
        setName('');
        setNameError('')
        setId('0');
        setEditFlag(false)
        setDepartment('')
        setDepartmentError('')
    }

    //add hall
    const addHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true
        }

        if (department === '') {
            setDepartmentError('Department is required');
            error = true
        }


        const addData = {
            name: name,
            department: department,

        };

        if (!error) {
            dispatch(addCategory(addData)).then((text) => {
                toast('Category Added', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }


    const editHandler = (hl: any) => {
        console.log('edit');
        console.log(hl);

        setEditFlag(true);
        setId(hl.id ? hl.id : '0');
        setName(hl.Name);
        setDepartment(hl.Department)

    }
    console.log(name);

    const updateHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true
        }

        if (department === '') {
            setDepartmentError('Department is required');
            error = true
        }

        const editData = {
            id: id,
            name: name,
            department: department,

        };

        if (!error) {
            dispatch(editCategory(editData, id)).then(text => {
                toast('Category Updated', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })

        }
    }

    const deleteHandler = (id: string) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteCategory(id)).then(text => {
                toast('Category Deleted', ToastTypes.SUCCESS)
                closeDeleteConfirmation()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const head = [
        { field: 'id', headerName: 'S.No', width: 200, headerClassName: 'bg-secondary text-white' },
        { field: 'Name', headerName: 'Category Name', width: 300, headerClassName: 'bg-secondary text-white' },
        { field: 'Department', headerName: 'Department', width: 300, headerClassName: 'bg-secondary text-white' },
        {
            field: 'Action', headerName: 'Action', width: 250, headerClassName: 'bg-secondary text-white', renderCell: (params: any) => (
                <div className='hstack gap-1 m-2'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(params.row)}><Feather.Edit size={20} /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(params.row.id)}><Feather.Trash size={20} /></button>
                </div>
            ),
        },
    ]

    const body: any[] = []

    const csvData = [
        ['S.No', 'Name', 'Department']
    ]

    //table
    CategoryList.forEach((e, i) => {
        body.push({
            id: (i + 1).toString(),
            Name: e.name,
            Department: e.department,
            Action: (
                <div className='hstack gap-1'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(e)}> <Feather.Edit /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(e.id!)}> <Feather.Delete /></button>
                </div>
            )
        })
    })

    //csv
    CategoryList.forEach((e, i) => {
        csvData.push([(i + 1).toString(), e.name, e.department])
    })

    return <>
        <PageLayout>
            <div>
                <PageTitle
                    title="Category"
                    buttonText=""
                />
                <Card className="m-3" title={editFlag ? 'Edit Category' : 'Add Category'}>
                    <div className='container m-0'>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                                <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                    setName(e.target.value)
                                    setNameError('')
                                }} value={name} />
                                {nameError && <div className="text-danger">{nameError}</div>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Department <span className="text-danger">*</span> : </label>
                                <input className="form-control" placeholder="Enter Department..." type="text" name="Department" onChange={(e) => {
                                    setDepartment(e.target.value)
                                    setDepartmentError('')
                                }} value={department} />
                                {departmentError && <div className="text-danger">{departmentError}</div>}
                            </div>

                        </div>

                        <div className="col m-4 d-flex justify-content-end">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>

                    </div>
                </Card>
                <CollapseCard title="Category">
                    <div className="container-fluid p-2" style={{ width: "100%" }}>
                        <Table
                            head={head}
                            body={body}
                        />

                    </div>
                    <div className="text-end mb-3">
                        {/* {examTimetableData.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>} */}
                        {/* {CategoryList.length > 0 && (<CSVLink
                            data={csvData}
                            filename="exam_timetable_report.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>)} */}
                    </div>
                </CollapseCard>
                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </PageLayout>
    </>
}
export default Category