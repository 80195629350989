import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"


export interface DepartmentType {
    id?: string
    name: string

}

export const DepartmentSampleData = [
    {
        id: '1',
        name: 'Dep 1',
    },
    {
        id: '2',
        name: 'Dep 2',
    },
    {
        id: '3',
        name: 'Dep 3',
    },
    {
        id: '4',
        name: 'Dep 4',
    },
    {
        id: '5',
        name: 'Dep 5',
    },
]

export enum DepartmentActionsList {
    ADD_DEPARTMENT = 'ADD_DEPARTMENT',
    EDIT_DEPARTMENT = 'EDIT_DEPARTMENT',
    FETCH_DEPARTMENT = 'FETCH_DEPARTMENT',
    DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
}

export interface DeleteDepartment {
    id: string
}

export interface AddDepartmentAction {
    [key: string]: any;
    type: DepartmentActionsList.ADD_DEPARTMENT
    data: DepartmentType
}

export interface EditDepartmentAction {
    [key: string]: any;
    type: DepartmentActionsList.EDIT_DEPARTMENT
    data: DepartmentType
    id: string
}

export interface FetchDepartmentAction {
    [key: string]: any;
    type: DepartmentActionsList.FETCH_DEPARTMENT
    // data: Department[]
}

export interface DeleteDepartmentAction {
    [key: string]: any;
    type: DepartmentActionsList.DELETE_DEPARTMENT
    data: DeleteDepartment
}

export type DepartmentActions = AddDepartmentAction | EditDepartmentAction | FetchDepartmentAction | DeleteDepartmentAction

export const addDepartment = (data: DepartmentType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddDepartmentAction>({
            type: DepartmentActionsList.ADD_DEPARTMENT,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editDepartment = (data: DepartmentType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditDepartmentAction>({
            type: DepartmentActionsList.EDIT_DEPARTMENT,
            data: data,
            id: id
        })
    }
}

export const fetchDepartment = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchDepartmentAction>({
            type: DepartmentActionsList.FETCH_DEPARTMENT
            // data: response.data
        })
    }
}

export const deleteDepartment = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteDepartmentAction>({
            type: DepartmentActionsList.DELETE_DEPARTMENT,
            data: { id }
        })
    }
}