import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import Category from "../pages/Category"
import Customer from "../pages/Customer"
import Department from "../pages/Department"
import { Home } from "../pages/Home"
import Lead from "../pages/Lead"
import LocateCustomer from "../pages/LocateCustomer"
import LocateLead from "../pages/LocateLead"
import { Login } from "../pages/Login"
import Logout from "../pages/Logout"
import Product from "../pages/Product"
import Staff from "../pages/Staff"
import { AuthUserType } from "../redux/actions/authUserActions"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"

export const Router = () => {
    const dispatch = useDispatch<AppDispatch>
    const authUser = useSelector<StoreState, AuthUserType>((state: any) => state.authUser)

    // const authenticated = true
    const authenticated = authUser && typeof authUser.token === 'string' && authUser.token !== undefined && authUser.token !== ''

    useEffect(() => {
        // dispatch(fetchProduct)
    }, [authenticated])

    return <>
        < BrowserRouter >

            {/* {authenticated && <TopNavBar />} */}
            {/* {authenticated && <SideNavBar />} */}

            <Routes>
                <Route path='/' element={authenticated ? <Home /> : <Navigate to='/login' />}></Route>
                <Route path='/login' element={!authenticated ? <Login /> : <Navigate to='/' />}></Route>
                <Route path='/logout' element={authenticated ? <Logout /> : <Navigate to='/' />}></Route>
                <Route path='/Product' element={authenticated ? <Product /> : <Navigate to='/' />}></Route>
                <Route path='/Lead' element={authenticated ? <Lead /> : <Navigate to='/' />}></Route>
                <Route path='/LocateLead' element={authenticated ? <LocateLead /> : <Navigate to='/' />}></Route>
                <Route path='/LocateCustomer' element={authenticated ? <LocateCustomer /> : <Navigate to='/' />}></Route>
                <Route path='/Category' element={authenticated ? <Category /> : <Navigate to='/' />}></Route>
                <Route path='/Department' element={authenticated ? <Department /> : <Navigate to='/' />}></Route>
                <Route path='/Customer' element={authenticated ? <Customer /> : <Navigate to='/' />}></Route>
                <Route path='/Staff' element={authenticated ? <Staff /> : <Navigate to='/' />}></Route>
                <Route path='/Dashboard' element={authenticated ? <Home /> : <Navigate to='/' />}></Route>
            </Routes>

            <ToastContainer />

            {/* {authenticated && <Footer />} */}
            {/* {authenticated && <ControlSideNavBar />} */}
        </BrowserRouter >
    </>
}