import { combineReducers } from "redux";
import { AuthUserActionList } from "../actions/authUserActions";
import { Action } from "../actions/types";
import authUserReducer from "./authUserReducer";
import { categoryReducer } from "./categoryReducer";
import { customerReducer } from "./customerReducer";
import { departmentReducer } from "./departmentReducer";
import { leadReducer } from "./leadReducer";
import { productReducer } from "./productReducer";
import { staffReducer } from "./staffReducer";

const combinedReducers = combineReducers({
    authUser: authUserReducer,
    product: productReducer,
    lead: leadReducer,
    category: categoryReducer,
    department: departmentReducer,
    customer: customerReducer,
    staff: staffReducer,
})

const reducers = (state: StoreState | undefined, action: Action) => {
    if (action.type === AuthUserActionList.LOGOUT_USER) {
        state = undefined
    }
    return combinedReducers(state, action)
}

export type StoreState = ReturnType<typeof combinedReducers>
export type GetState = () => StoreState

export default reducers