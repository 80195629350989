import { ProductActionsList, ProductSampleData, ProductType } from "../actions/productActions";
import { Action } from "../actions/types";


export const productReducer = (state: ProductType[] = ProductSampleData, action: Action) => {

    switch (action.type) {

        case ProductActionsList.ADD_PRODUCT: {

            return [...state, action.data]
        }

        case ProductActionsList.EDIT_PRODUCT: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case ProductActionsList.FETCH_PRODUCT: {

            return state
        }

        case ProductActionsList.DELETE_PRODUCT: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}