import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum UnitDropDown {
    KG = 'KG',
    LTR = 'LTR',
    PIECE = 'PIECE',
    BAG_60KG = 'BAG - 60KG',

}
export type UnitTypes = UnitDropDown.KG | UnitDropDown.LTR | UnitDropDown.PIECE | UnitDropDown.BAG_60KG

export interface ProductType {
    id?: string
    name: string
    category: string
    unit: UnitTypes
    pricePerUnit: number
}

export const ProductSampleData = [
    {
        id: '1',
        category: 'Rice',
        name: 'Ponni Rice',
        unit: UnitDropDown.BAG_60KG,
        pricePerUnit: 750
    },
    {
        id: '2',
        category: 'Rice',
        name: 'Seeraga Samba Rice',
        unit: UnitDropDown.BAG_60KG,
        pricePerUnit: 200
    },
    {
        id: '3',
        category: 'Oil',
        name: 'Sesame Oil',
        unit: UnitDropDown.LTR,
        pricePerUnit: 500
    },
    {
        id: '4',
        category: 'Oil',
        name: 'Cocunut Oil',
        unit: UnitDropDown.LTR,
        pricePerUnit: 200
    },
    {
        id: '5',
        category: 'Oil',
        name: 'Groundnut Oil',
        unit: UnitDropDown.LTR,
        pricePerUnit: 250
    },
]

export enum ProductActionsList {
    ADD_PRODUCT = 'ADD_PRODUCT',
    EDIT_PRODUCT = 'EDIT_PRODUCT',
    FETCH_PRODUCT = 'FETCH_PRODUCT',
    DELETE_PRODUCT = 'DELETE_PRODUCT'
}

export interface DeleteProduct {
    id: string
}

export interface AddProductAction {
    [key: string]: any;
    type: ProductActionsList.ADD_PRODUCT
    data: ProductType
}

export interface EditProductAction {
    [key: string]: any;
    type: ProductActionsList.EDIT_PRODUCT
    data: ProductType
    id: string
}

export interface FetchProductAction {
    [key: string]: any;
    type: ProductActionsList.FETCH_PRODUCT
    // data: Product[]
}

export interface DeleteProductAction {
    [key: string]: any;
    type: ProductActionsList.DELETE_PRODUCT
    data: DeleteProduct
}

export type ProductActions = AddProductAction | EditProductAction | FetchProductAction | DeleteProductAction

export const addProduct = (data: ProductType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddProductAction>({
            type: ProductActionsList.ADD_PRODUCT,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editProduct = (data: ProductType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditProductAction>({
            type: ProductActionsList.EDIT_PRODUCT,
            data: data,
            id: id
        })
    }
}

export const fetchProduct = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchProductAction>({
            type: ProductActionsList.FETCH_PRODUCT
            // data: response.data
        })
    }
}

export const deleteProduct = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteProductAction>({
            type: ProductActionsList.DELETE_PRODUCT,
            data: { id }
        })
    }
}