import './App.css';
import { Router } from './components/Router';
// import './style/index.scss';

function App() {
    return (
        <Router />
    );
}

export default App;
