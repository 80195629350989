// import Card from '@mui/material/Card';
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import * as Feather from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../components/PageLayout";
import PageTitle from "../components/PageTitle";
import Table from "../components/Table";
import CollapseCard from "../components/custom/CollapseCard";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { ProductType, UnitDropDown, UnitTypes, addProduct, deleteProduct, editProduct } from "../redux/actions/productActions";
import { StoreState } from "../redux/reducers";
import { AppDispatch } from "../redux/store";

const Product = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)

    const [editFlag, setEditFlag] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [categoryError, setCategoryError] = useState<string>('');
    const [unit, setUnit] = useState<UnitTypes>(UnitDropDown.PIECE);
    const [unitError, setUnitError] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [priceError, setPriceError] = useState<string>('');
    const [id, setId] = useState<string>('0');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const clearHandler = () => {
        setName('');
        setNameError('')
        setPrice('');
        setPriceError('')
        setId('0');
        setEditFlag(false)
        setCategory('')
        setCategoryError('')
        setUnit(UnitDropDown.KG)
        setUnitError('')

    }

    //add hall
    const addHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true
        }
        if (price === '') {
            setPriceError('Price is required');
            error = true
        } else if (price.length > 11) {
            setPriceError('Price must be within 11 characters');
            error = true
        }
        if (category === '') {
            setCategoryError('Category is required');
            error = true
        }
        if (unit.toString() === '') {
            setUnitError('Unit is required');
            error = true
        }

        const addData = {
            name: name,
            category: category,
            unit: unit,
            pricePerUnit: parseFloat(price)
        };

        if (!error) {
            dispatch(addProduct(addData)).then((text) => {
                toast('Product Added', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }


    const editHandler = (hl: any) => {
        console.log('edit');
        console.log(hl);

        setEditFlag(true);
        setId(hl.id ? hl.id : '0');
        setName(hl.Product);
        setCategory(hl.Category)
        setUnit(hl.Unit)
        setPrice(hl.Price?.toString());
    }
    console.log(name);

    const updateHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true
        }
        if (price === '') {
            setPriceError('Price is required');
            error = true
        } else if (price.length > 11) {
            setPriceError('Price must be within 11 characters');
            error = true
        }
        if (category === '') {
            setCategoryError('Category is required');
            error = true
        }
        if (unit.toString() === '') {
            setUnitError('Unit is required');
            error = true
        }

        const editData = {
            id: id,
            name: name,
            category: category,
            unit: unit,
            pricePerUnit: parseFloat(price)
        };
        if (!error) {
            dispatch(editProduct(editData, id)).then(text => {
                toast('Product Updated', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })

        }
    }

    const deleteHandler = (id: string) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteProduct(id)).then(text => {
                toast('Product Deleted', ToastTypes.SUCCESS)
                closeDeleteConfirmation()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const head = [
        { field: 'id', headerName: 'S.No', width: 130, headerClassName: 'bg-secondary text-white' },
        { field: 'Product', headerName: 'Product Name', width: 200, headerClassName: 'bg-secondary text-white' },
        { field: 'Category', headerName: 'Category', width: 200, headerClassName: 'bg-secondary text-white' },
        { field: 'Unit', headerName: 'Unit', width: 200, headerClassName: 'bg-secondary text-white' },
        { field: 'Price', headerName: 'Price', width: 180, headerClassName: 'bg-secondary text-white' },
        {
            field: 'Action', headerName: 'Action', width: 200, headerClassName: 'bg-secondary text-white', renderCell: (params: any) => (
                <div className='hstack gap-1 m-2'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(params.row)}><Feather.Edit size={20} /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(params.row.id)}><Feather.Trash size={20} /></button>
                </div>
            ),
        },
    ]

    const body: any[] = []

    const csvData = [
        ['S.No', 'Product Name', 'Category', 'Unit', 'Price']
    ]
    console.log(ProductList);

    //table
    ProductList.forEach((e, i) => {
        body.push({
            id: (i + 1).toString(),
            Product: e.name,
            Category: e.category,
            Unit: e.unit,
            Price: e.pricePerUnit?.toString(),
            Action: (
                <div className='hstack gap-1'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(e)}> <Feather.Edit /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(e.id!)}> <Feather.Delete /></button>
                </div>
            )
        })
    })

    //csv
    ProductList.forEach((e, i) => {
        csvData.push([(i + 1).toString(), e.name, e.category, e.unit, e.pricePerUnit?.toString()])
    })

    return <>
        <PageLayout>
            <div>
                <PageTitle
                    title="Product"
                    buttonText=""
                />
                <Card className="m-3" title={editFlag ? 'Edit Product' : 'Add Product'}>
                    <div className='container m-0'>
                        <div className="row">
                            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                                <FormInput
                                    label='Name'
                                    name=""
                                    labelClassName="required"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setNameError('')
                                    }}
                                    placeholder='Name'
                                    errorText={nameError}
                                    containerClass="mb-2"
                                />
                            </div> */}
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                                <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                    setName(e.target.value)
                                    setNameError('')
                                }} value={name} />
                                {nameError && <div className="text-danger">{nameError}</div>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Category <span className="text-danger">*</span> : </label>
                                <input className="form-control" placeholder="Enter category..." type="text" name="category" onChange={(e) => {
                                    setCategory(e.target.value)
                                    setCategoryError('')
                                }} value={category} />
                                {categoryError && <div className="text-danger">{categoryError}</div>}
                            </div>
                            <div className="col-lg-2 col-sm-6 col-md-2">
                                <label className="mt-3 mb-3">Unit <span className="text-danger">*</span> : </label>
                                <select className="form-control" name="Unit" onChange={(e) => {
                                    setUnit(e.target.value as UnitTypes)
                                    setUnitError('')
                                }} value={unit} >
                                    <option value="">Select</option>
                                    <option value={UnitDropDown.PIECE}>{UnitDropDown.PIECE}</option>
                                    <option value={UnitDropDown.KG}>{UnitDropDown.KG}</option>
                                    <option value={UnitDropDown.LTR}>{UnitDropDown.LTR}</option>
                                    <option value={UnitDropDown.BAG_60KG}>{UnitDropDown.BAG_60KG}</option>
                                </select>
                                {unitError && <div className="text-danger">{unitError}</div>}
                            </div>
                            <div className="col-lg-2 col-sm-6 col-md-2">
                                <label className="mt-3 mb-3">Price <span className="text-danger">*</span> : </label>
                                <input className="form-control" placeholder="Enter Price..." type="number" name="price" onChange={(e) => {
                                    setPrice(e.target.value)
                                    setPriceError('')
                                }} value={price} />
                                {priceError && <div className="text-danger">{priceError}</div>}
                            </div>

                        </div>

                        <div className="col m-4 d-flex justify-content-end">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>

                    </div>
                </Card>
                <CollapseCard title="Product">
                    <div className="container-fluid p-2" style={{ width: "100%" }}>
                        <Table
                            head={head}
                            body={body}
                        />
                        {/* <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Seating Capacity</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hallList.map((hl, index) => (
                                    <tr key={hl.id}>
                                        <td>{index + 1}</td>
                                        <td>{hl.name}</td>
                                        <td>{hl.price}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(hl)}>Edit</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(hl.id ? hl.id : 0)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                    <div className="text-end mb-3">
                        {/* {examTimetableData.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>} */}
                        {/* {ProductList.length > 0 && (<CSVLink
                            data={csvData}
                            filename="exam_timetable_report.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>)} */}
                    </div>
                </CollapseCard>
                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </PageLayout>
    </>
}
export default Product