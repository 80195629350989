import { LeadActionsList, LeadType, LeadsampleData } from "../actions/leadActions";
import { Action } from "../actions/types";


export const leadReducer = (state: LeadType[] = LeadsampleData, action: Action) => {

    switch (action.type) {

        case LeadActionsList.ADD_LEAD: {

            return [...state, action.data]
        }

        case LeadActionsList.EDIT_LEAD: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case LeadActionsList.EDIT_STATUS: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...newState[index],
                    id: action.id,
                    status: action.data
                }
            }
            return newState
        }

        case LeadActionsList.FETCH_LEAD: {

            return state
        }

        case LeadActionsList.DELETE_LEAD: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}