import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"


export interface CategoryType {
    id?: string
    name: string
    department: string

}

export const CategorySampleData = [
    {
        id: '1',
        name: 'Rice',
        department: 'Dep 1'
    },
    {
        id: '2',
        name: 'Oil',
        department: 'Dep 2'
    },
    {
        id: '3',
        name: 'Grains',
        department: 'Dep 3'
    },
    {
        id: '4',
        name: 'Millets',
        department: 'Dep 4'
    },
    {
        id: '5',
        name: 'Nuts',
        department: 'Dep 5'
    },
]

export enum CategoryActionsList {
    ADD_CATEGORY = 'ADD_CATEGORY',
    EDIT_CATEGORY = 'EDIT_CATEGORY',
    FETCH_CATEGORY = 'FETCH_CATEGORY',
    DELETE_CATEGORY = 'DELETE_CATEGORY'
}

export interface DeleteCategory {
    id: string
}

export interface AddCategoryAction {
    [key: string]: any;
    type: CategoryActionsList.ADD_CATEGORY
    data: CategoryType
}

export interface EditCategoryAction {
    [key: string]: any;
    type: CategoryActionsList.EDIT_CATEGORY
    data: CategoryType
    id: string
}

export interface FetchCategoryAction {
    [key: string]: any;
    type: CategoryActionsList.FETCH_CATEGORY
    // data: Category[]
}

export interface DeleteCategoryAction {
    [key: string]: any;
    type: CategoryActionsList.DELETE_CATEGORY
    data: DeleteCategory
}

export type CategoryActions = AddCategoryAction | EditCategoryAction | FetchCategoryAction | DeleteCategoryAction

export const addCategory = (data: CategoryType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddCategoryAction>({
            type: CategoryActionsList.ADD_CATEGORY,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editCategory = (data: CategoryType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditCategoryAction>({
            type: CategoryActionsList.EDIT_CATEGORY,
            data: data,
            id: id
        })
    }
}

export const fetchCategory = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchCategoryAction>({
            type: CategoryActionsList.FETCH_CATEGORY
            // data: response.data
        })
    }
}

export const deleteCategory = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteCategoryAction>({
            type: CategoryActionsList.DELETE_CATEGORY,
            data: { id }
        })
    }
}