import PageLayout from "../components/PageLayout"
import { Dashboard } from "./Dashboard"

export const Home = () => {
    return <>
        <PageLayout>
            <h4 className="mt-2">Welcome to CRM</h4>
            <div className="container">
                <Dashboard />
            </div>
        </PageLayout>
    </>
}