import { ProgressBar } from "react-bootstrap"

export const Dashboard = () => {
    return <>
        <div className="row mt-2 hstack gap-1">
            {/* <h4></h4> */}
            <div className="card m-2 ms-3" style={{ width: '260px', height: '80px' }}>
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <h6 className="card-title text-secondary">Invoices Awaiting Payment</h6>
                        <span className="text-secondary">8/10</span>
                    </div>
                    <div className='text mt-1-info'>
                        <ProgressBar now={8} max={10} variant="info" striped animated style={{ height: '7px' }} />
                    </div>
                </div>
            </div>
            <div className="card m-2" style={{ width: '260px', height: '80px' }}>
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <h6 className="card-title text-secondary">Converted Leads</h6>
                        <span className="text-secondary">3/5</span>
                    </div>
                    <div className='text-warning mt-1'>
                        <ProgressBar now={3} max={5} variant="warning" striped animated style={{ height: '7px' }} />
                    </div>
                </div>
            </div>
            <div className="card m-2" style={{ width: '260px', height: '80px' }}>
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <h6 className="card-title text-secondary">Projects in Progress</h6>
                        <span className="text-secondary">80/100</span>
                    </div>
                    <div className='text-danger mt-1'>
                        <ProgressBar now={80} max={100} variant="danger" striped animated style={{ height: '7px' }} />
                    </div>
                </div>
            </div>
            <div className="card m-2" style={{ width: '260px', height: '80px' }}>
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <h6 className="card-title text-secondary">Tasks Not Finished</h6>
                        <span className="text-secondary">20/25</span>
                    </div>
                    <div className='text-success mt-1'>
                        <ProgressBar now={20} max={25} variant="success" striped animated style={{ height: '7px' }} />
                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-2 mx-0">
            <div className="card2">
                <div className="card-body">
                    <h5 className="card-title">Tasks Summary</h5>
                    <div className="row">
                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">45</h5>
                                <p className='text-info card-subtitle'>Not Started </p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">56</h5>
                                <p className='text-info card-subtitle'>In progress </p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">77</h5>
                                <p className='text-info card-subtitle'>Testing </p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">0</h5>
                                <p className='text-info card-subtitle'>Awaiting Feedback </p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">4</h5>
                                <p className='text-info card-subtitle'>Complete </p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">6</h5>
                                <p className='text-info card-subtitle'>Not Billable </p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">57</h5>
                                <p className='text-info card-subtitle'>Billable</p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4 me-1" style={{ width: '260px', height: '100px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">0</h5>
                                <p className='text-info card-subtitle'>Billed</p>
                                <p className='text-secondary card-subtitle'>Tasks assigned to me : 0</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
        {/* {contract} */}
        <div className="row mt-2 mx-0">
            <div className="card2">
                <div className="card-body">
                    <h5 className="card-title ">Contract Summary</h5>
                    <div className="row">
                        <div className="card col-lg-4 col-sm-6 col-md-4" style={{ width: '210px', height: '80px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">45</h5>
                                <p className='text-info card-subtitle'>Active </p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4" style={{ width: '210px', height: '80px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">56</h5>
                                <p className='text-warning card-subtitle'>Expired </p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4" style={{ width: '210px', height: '80px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">77</h5>
                                <p className='text-danger card-subtitle'>About to expire</p>
                            </div>
                        </div>

                        <div className="card col-lg-4 col-sm-6 col-md-4" style={{ width: '210px', height: '80px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">0</h5>
                                <p className='text-success card-subtitle'>Recently Added </p>
                            </div>
                        </div>
                        <div className="card col-lg-4 col-sm-6 col-md-4" style={{ width: '210px', height: '80px' }}>
                            <div className="card-body">
                                <h5 className="card-title text-secondary">0</h5>
                                <p className='text-primary card-subtitle'>Trash</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
}