import { FC } from "react";

const Footer: FC = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="main-footer h-stack" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <span>Powered By</span><a href="http://www.mindzcube.com"><img src={process.env.PUBLIC_URL + "img/mindzcube.png"} height="25px" alt="" /></a>
            </span>
            <div className="float-right d-none d-sm-block">
                Copyright &copy; {currentYear}. All rights reserved.
            </div>
        </footer>
    )
}
export default Footer;