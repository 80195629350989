import { AuthUserActionList, AuthUserType } from "../actions/authUserActions"
import { Action } from "../actions/types"

const initialValue: AuthUserType | null = null

const authUserReducer = (state: AuthUserType | null = initialValue, action: Action) => {
    switch (action.type) {
        case AuthUserActionList.LOGIN_USER:
            return action.data

        case AuthUserActionList.LOGOUT_USER:
            return initialValue

        default:
            return state
    }
}

export default authUserReducer