import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export interface LeadType {
    id?: string
    name: string
    address: string
    phone: string
    phone2: string
    tag: string
    status: string
    assignedTo: string
    position: string
    email: string
    city: string
    state: string
    country: string
    zipcode: number
    description: string
    latitude?: number,
    longitude?: number,
}

export const LeadsampleData = [
    {
        id: "1",
        name: "Raj Kumar",
        address: "98 Marina Beach Road",
        phone: "9841567890",
        phone2: "9841665432",
        tag: "Lead",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Manager",
        email: "raj.kumar@example.com",
        city: "Chennai",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 600006,
        latitude: 13.0475,
        longitude: 80.2803,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
        id: "2",
        name: "Sunita Menon",
        address: "12 Gandhi Nagar",
        phone: "9841755555",
        phone2: "",
        tag: "Lead",
        status: "Active",
        assignedTo: "Marketing Team",
        position: "Coordinator",
        email: "sunita.menon@example.com",
        city: "Madurai",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 625001,
        latitude: 9.9252,
        longitude: 78.1198,
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
        id: "3",
        name: "Mohan Raj",
        address: "45 South Street",
        phone: "9841811122",
        phone2: "9841933344",
        tag: "Prospect",
        status: "Inactive",
        assignedTo: "Support Team",
        position: "Developer",
        email: "mohan.raj@example.com",
        city: "Coimbatore",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 641001,
        latitude: 11.0168,
        longitude: 76.9558,
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: "4",
        name: "Aishwarya Devi",
        address: "76 North Avenue",
        phone: "9842077788",
        phone2: "",
        tag: "Lead",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Sales Representative",
        email: "aishwarya.devi@example.com",
        city: "Salem",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 636001,
        latitude: 11.6643,
        longitude: 78.1460,
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
        id: "5",
        name: "Gopal Iyer",
        address: "23 East Street",
        phone: "9842199999",
        phone2: "",
        tag: "Lead",
        status: "Inactive",
        assignedTo: "Marketing Team",
        position: "Analyst",
        email: "gopal.iyer@example.com",
        city: "Madurai",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 625002,
        latitude: 9.9312,
        longitude: 78.1218,
        description: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: "6",
        name: "Ravi Krishna",
        address: "56 West Park",
        phone: "9842223456",
        phone2: "9842387654",
        tag: "Lead",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Manager",
        email: "ravi.krishna@example.com",
        city: "Coimbatore",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 641002,
        latitude: 11.0183,
        longitude: 76.9569,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
        id: "7",
        name: "Vidhya Lakshmi",
        address: "89 Temple Road",
        phone: "9842434567",
        phone2: "",
        tag: "Lead",
        status: "Active",
        assignedTo: "Marketing Team",
        position: "Coordinator",
        email: "vidhya.lakshmi@example.com",
        city: "Salem",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 636002,
        latitude: 11.6694,
        longitude: 78.1437,
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
        id: "8",
        name: "Sanjay Mehta",
        address: "101 Hill View",
        phone: "9842545678",
        phone2: "9842609876",
        tag: "Prospect",
        status: "Inactive",
        assignedTo: "Support Team",
        position: "Developer",
        email: "sanjay.mehta@example.com",
        city: "Erode",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 638001,
        latitude: 11.3426,
        longitude: 77.7282,
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        id: "9",
        name: "Nalini Reddy",
        address: "34 Lake View",
        phone: "9842756789",
        phone2: "",
        tag: "Lead",
        status: "Active",
        assignedTo: "Sales Team",
        position: "Sales Representative",
        email: "nalini.reddy@example.com",
        city: "Vellore",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 632001,
        latitude: 12.9345,
        longitude: 79.1331,
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
        id: "10",
        name: "Prakash Suresh",
        address: "567 Beach Road",
        phone: "9842867890",
        phone2: "",
        tag: "Lead",
        status: "Inactive",
        assignedTo: "Marketing Team",
        position: "Analyst",
        email: "prakash.suresh@example.com",
        city: "Tuticorin",
        state: "Tamil Nadu",
        country: "India",
        zipcode: 628001,
        latitude: 8.7642,
        longitude: 78.1348,
        description: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    }
];

export enum LeadActionsList {
    ADD_LEAD = 'ADD_LEAD',
    EDIT_LEAD = 'EDIT_LEAD',
    FETCH_LEAD = 'FETCH_LEAD',
    DELETE_LEAD = 'DELETE_LEAD',
    EDIT_STATUS = 'EDIT_STATUS'
}

export interface DeleteLead {
    id: string
}

export interface AddLeadAction {
    [key: string]: any;
    type: LeadActionsList.ADD_LEAD
    data: LeadType
}

export interface EditLeadAction {
    [key: string]: any;
    type: LeadActionsList.EDIT_LEAD
    data: LeadType
    id: string
}

export interface EditStatusAction {
    [key: string]: any;
    type: LeadActionsList.EDIT_STATUS
    data: string
    id: string
}

export interface FetchLeadAction {
    [key: string]: any;
    type: LeadActionsList.FETCH_LEAD
    // data: Lead[]
}

export interface DeleteLeadAction {
    [key: string]: any;
    type: LeadActionsList.DELETE_LEAD
    data: DeleteLead
}

export type LeadActions = AddLeadAction | EditLeadAction | FetchLeadAction | DeleteLeadAction | EditStatusAction

export const addLead = (data: LeadType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddLeadAction>({
            type: LeadActionsList.ADD_LEAD,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editLead = (data: LeadType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditLeadAction>({
            type: LeadActionsList.EDIT_LEAD,
            data: data,
            id: id
        })
    }
}

export const editStatus = (status: string, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditStatusAction>({
            type: LeadActionsList.EDIT_STATUS,
            data: status,
            id: id
        })
    }
}

export const fetchLead = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchLeadAction>({
            type: LeadActionsList.FETCH_LEAD
            // data: response.data
        })
    }
}

export const deleteLead = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteLeadAction>({
            type: LeadActionsList.DELETE_LEAD,
            data: { id }
        })
    }
}