import { CustomerActionsList, CustomersampleData, CustomerType } from "../actions/customerActions";
import { Action } from "../actions/types";


export const customerReducer = (state: CustomerType[] = CustomersampleData, action: Action) => {

    switch (action.type) {

        case CustomerActionsList.ADD_CUSTOEMER: {

            return [...state, action.data]
        }

        case CustomerActionsList.EDIT_CUSTOEMER: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case CustomerActionsList.EDIT_STATUS: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...newState[index],
                    id: action.id,
                    status: action.data
                }
            }
            return newState
        }

        case CustomerActionsList.FETCH_CUSTOEMER: {

            return state
        }

        case CustomerActionsList.DELETE_CUSTOEMER: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}