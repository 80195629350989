import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
    OutputFormat,
    setDefaults
} from "react-geocode";
import { useSelector } from "react-redux";
import Map3, { Poi } from "../components/Map3";
import { LeadType } from "../redux/actions/leadActions";
import { StoreState } from "../redux/reducers";

const LocateLead = () => {
    const LeadList = useSelector<StoreState, LeadType[]>(state => state.lead)
    const [convertedData, setConvertedData] = useState<Poi[]>([])

    const addresses = [
        '1600 Amphitheatre Parkway, Mountain View, CA',
        'One Apple Park Way, Cupertino, CA'
    ];
    setDefaults({
        key: 'AIzaSyB9xt8FtUV0OULAfisuT3c3kosNozs0ky4', // Your API key here.
        language: "en", // Default language for responses.
        region: "es", // Default region for responses.
        outputFormat: OutputFormat.JSON
    });

    // useEffect(() => {
    //     let array: Poi[] = []
    //     LeadList.forEach((l, i) => {
    //         //
    //         geocode(RequestType.ADDRESS, l.address + ',' + l.city + ',' + l.state + ',' + l.country + ',' + l.zipcode)
    //             .then(({ results }) => {
    //                 const { lat, lng } = results[0].geometry.location;
    //                 console.log(lat, lng);
    //                 array.push({
    //                     key: i?.toString(),
    //                     location: { lat: lat, lng: lng }
    //                 })
    //             })
    //     })
    //     setConvertedData(array)
    // }, [LeadList])

    useEffect(() => {
        let array: Poi[] = []
        LeadList.forEach((l, i) => {
            if (l.latitude && l.longitude) {
                array.push({
                    key: i?.toString(),
                    location: { lat: l.latitude, lng: l.longitude }
                })
            }
        })
        setConvertedData(array)
    }, [LeadList])

    // geocode(RequestType.ADDRESS, "Eiffel Tower")
    //     .then(({ results }) => {
    //         const { lat, lng } = results[0].geometry.location;
    //         console.log(lat, lng);
    //     })

    return <>
        <div className="mx-1">
            <div className="row m-1">
                <div className="col-3 card overflow-scroll" style={{ height: '600px' }}>
                    <h4>Leads</h4>
                    {LeadList.map((l, i) => {
                        return <Card className="m-1" key={i}>
                            <Card.Header className="fw-bold">{l.name}</Card.Header>
                            <Card.Body>
                                <p>Address: {l.address},{l.city},{l.state},{l.country}</p>
                                <span>PH: {l.phone}</span>
                            </Card.Body>
                        </Card>
                    })}

                </div>
                <div className="col-9">
                    {/* <SimpleMap /> */}
                    {/* <MapComponent
                    addresses={addresses}
                /> */}
                    <Map3
                        data={convertedData}
                    />
                </div>
            </div>
        </div>
    </>
}
export default LocateLead;