/* eslint-disable no-throw-literal */
import { nanoid } from "nanoid"
import { Action, Dispatch } from "redux"

export interface LoginDataType {
    userId: string
    password: string
}

interface RoleType {
    id?: string
    role: string
    page_title: string
    page_url: string
    add: boolean
    view: boolean
    edit: boolean
    delete: boolean
    createdBy?: string | null,
    createdAt?: string,
    updatedAt?: string
}

export interface AuthUserType {
    id?: number
    token?: string
    userId: string
    password?: string
    name: string
    branchId?: number
    email: string
    mobileNumber?: string
    status?: string
    roles?: RoleType[]
    createdAt?: string
    updatedAt?: string
}

const sampleUser: AuthUserType = {
    id: 1,
    userId: "Arul",
    name: "Arul",
    email: "john.doe@example.com",
    mobileNumber: "+1234567890",
    status: "active",
    token: nanoid(),
    roles: [
        {
            id: "4442618f3cc",
            role: "admin",
            page_title: "Role",
            page_url: "/admin/role",
            add: true,
            view: true,
            edit: true,
            delete: true,
            createdBy: "2",
            createdAt: "2023-08-09T11:53:20.809Z",
            updatedAt: "2023-08-09T11:53:20.809Z"
        },
        {
            id: "59c4120db20",
            role: "admin",
            page_title: "Portfolio Dashboard",
            page_url: "/",
            add: false,
            view: false,
            edit: false,
            delete: false,
            createdBy: "2",
            createdAt: "2023-08-09T11:53:20.809Z",
            updatedAt: "2023-08-09T11:53:20.809Z"
        },
    ],
    createdAt: "2024-01-01T12:00:00Z",
    updatedAt: "2024-06-01T09:30:00Z"
};

export enum AuthUserActionList {
    LOGIN_USER = 'LOGIN_USER',
    LOGOUT_USER = 'LOGOUT_USER',
}

// export interface LoginAction {
//     type: AuthUserActionList.LOGIN_USER
//     data: AuthUserType
// }

// export interface LogoutAction {
//     type: AuthUserActionList.LOGOUT_USER
//     data: null
// }

export interface LoginAction extends Action<AuthUserActionList.LOGIN_USER> {
    data: AuthUserType; // Adjust 'any' to the specific type if necessary
}

export interface LogoutAction extends Action<AuthUserActionList.LOGOUT_USER> {
    data: null;
}

export type AuthUserActions = LoginAction | LogoutAction

export const signupUser = (data: AuthUserType) => {
    // api call
}


// export const loginUser = (data: LoginDataType) => {
//     return async (dispatch: Dispatch) => {
//         // const response = await authLogin(data)
//         dispatch<AuthUserActions>({
//             type: AuthUserActionList.LOGIN_USER,
//             data: sampleUser
//         })
//     }
// }

export const loginUser = (data: LoginDataType) => {
    return async (dispatch: Dispatch<AuthUserActions>) => {
        // Simulate async action
        // setTimeout(() => {
        dispatch({
            type: AuthUserActionList.LOGIN_USER,
            data: sampleUser
        });
        // }, 1000); // Example: setTimeout simulates async operation
    };
};

export const logoutUser = () => {
    return (dispatch: Dispatch<AuthUserActions>) => {
        dispatch({
            type: AuthUserActionList.LOGOUT_USER,
            data: null
        });
    };
};