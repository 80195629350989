import { Divider, Drawer, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Category, Dashboard, PersonPin, ReceiptOutlined } from "@material-ui/icons";
import { Diversity3, Engineering, Inventory, Map, MyLocation } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";


const data = [
    {
        name: "Dashboard",
        icon: <Dashboard />, url: '/Dashboard',
    },
    { name: "Lead", icon: <PersonPin />, url: '/Lead' },
    { name: "Customer", icon: <Diversity3 />, url: '/Customer' },
    { name: "Staff", icon: <Engineering />, url: '/Staff' },
    { name: "Product", icon: <Inventory />, url: '/Product' },
    { name: "Category", icon: <Category />, url: '/Category' },
    { name: "Department", icon: <ReceiptOutlined />, url: '/Department' },
    { name: "Locate Lead", icon: <Map />, url: '/LocateLead' },
    { name: "Locate Customer", icon: <MyLocation />, url: '/LocateCustomer', Children: [] },
    // {
    //     name: "Locate", icon: <LocalActivity />, Children: [
    //         { name: "LocateLead", icon: <ChevronRight />, url: '/LocateLead' },
    //         { name: "LocateCustomer", icon: <ChevronRight />, url: '/LocateCustomer' },
    //     ]
    // },

];

interface Props {
    showSideMenu: boolean
    onMenuButtonClick: () => void
    setShowSideMenu: Dispatch<SetStateAction<boolean>>
}

const SideMenuBar = (props: Props) => {

    // utils/navigation.ts
    const openNewWindow = (path: string) => {
        const baseUrl = window.location.origin; // Get the base URL of the current site
        const fullUrl = `${baseUrl}${path}`;
        window.open(fullUrl, '_blank'); // '_blank' opens the URL in a new tab or window
    };

    const getList = () => (
        <div style={{ width: 250 }} onClick={() => { }}>
            {/* <div style={{ width: 250 }} onClick={() => setOpen(false)}> */}
            <Box onClick={props.onMenuButtonClick} sx={{ textAlign: 'center' }}>
                <Typography variant="h5" style={{ margin: '10px' }}>
                    CRM
                </Typography>
                <Divider />
                {data.map((item, index) => (

                    (item.Children && item.Children.length > 0)
                        ?
                        item.Children.map((ci, i) => {

                            return <ListItem button key={i} >
                                {/* <Link className='hstack gap-1' to={ci.url} style={{ color: '#70737C', textDecoration: 'none', margin: 0, padding: 0 }}>
                                    <ListItemIcon style={{ marginRight: 0, }}>{ci.icon}</ListItemIcon>
                                    <ListItemText primary={ci.name} />
                                </Link> */}
                            </ListItem>
                        })

                        : <ListItem button key={index} >
                            {/* onClick={item.url === '/Locate' ? () => openNewWindow(item.url) : undefined} */}
                            <Link className='hstack gap-1' to={item.url || '#'} style={{ color: '#70737C', textDecoration: 'none', margin: 0, padding: 0 }}>
                                <ListItemIcon style={{ marginRight: 0, }}>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.name} />
                            </Link>
                        </ListItem>
                ))}
            </Box>
        </div>
    );
    return (
        <div >
            {/* <Button onClick={() => setOpen(true)}>Click me</Button> */}
            <Drawer open={props.showSideMenu} anchor={"left"} onClose={() => props.setShowSideMenu(false)}>
                {getList()}
            </Drawer>
        </div>
    );
}

export default SideMenuBar;