import { CategoryActionsList, CategorySampleData, CategoryType } from "../actions/categoryActions";
import { Action } from "../actions/types";


export const categoryReducer = (state: CategoryType[] = CategorySampleData, action: Action) => {

    switch (action.type) {

        case CategoryActionsList.ADD_CATEGORY: {

            return [...state, action.data]
        }

        case CategoryActionsList.EDIT_CATEGORY: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case CategoryActionsList.FETCH_CATEGORY: {

            return state
        }

        case CategoryActionsList.DELETE_CATEGORY: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}