import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export interface StaffType {
    id?: string
    branchId: string
    email: string
    firstName: string
    lastName: string
    phone: string
    role: string
    status: string
    gender: string
    dateOfJoin: string
    dateOfBirth: string
    designation: string
    address: string
    lat: string
    lng: string
    linkedIn?: string
    skypeId?: string
}

export const StaffsampleData = [
    {
        id: "1",
        branchId: "101",
        email: "john.doe@example.com",
        firstName: "John",
        lastName: "Doe",
        phone: "+1234567890",
        role: "Manager",
        status: "Active",
        gender: "Male",
        dateOfJoin: "2021-01-15",
        dateOfBirth: "1985-06-25",
        designation: "Senior Manager",
        address: "123 Main St, Anytown, USA",
        lat: "40.712776",
        lng: "-74.005974",
        linkedIn: "https://www.linkedin.com/in/johndoe",
        skypeId: "john.doe"
    },
    {
        id: "2",
        branchId: "102",
        email: "jane.smith@example.com",
        firstName: "Jane",
        lastName: "Smith",
        phone: "+0987654321",
        role: "Developer",
        status: "Inactive",
        gender: "Female",
        dateOfJoin: "2020-05-23",
        dateOfBirth: "1990-11-15",
        designation: "Junior Developer",
        address: "456 Another St, Sometown, USA",
        lat: "34.052235",
        lng: "-118.243683",
        linkedIn: "https://www.linkedin.com/in/janesmith",
        skypeId: "jane.smith"
    },
    {
        id: "3",
        branchId: "103",
        email: "michael.brown@example.com",
        firstName: "Michael",
        lastName: "Brown",
        phone: "+1122334455",
        role: "Analyst",
        status: "Active",
        gender: "Male",
        dateOfJoin: "2019-03-12",
        dateOfBirth: "1982-08-19",
        designation: "Analyst",
        address: "789 Third St, Yourtown, USA",
        lat: "51.507351",
        lng: "-0.127758",
        linkedIn: "https://www.linkedin.com/in/michaelbrown",
        skypeId: "michael.brown"
    },
    {
        id: "4",
        branchId: "104",
        email: "emily.jones@example.com",
        firstName: "Emily",
        lastName: "Jones",
        phone: "+5566778899",
        role: "HR",
        status: "Active",
        gender: "Female",
        dateOfJoin: "2018-07-30",
        dateOfBirth: "1987-02-14",
        designation: "Senior HR",
        address: "101 First St, Mytown, USA",
        lat: "48.856613",
        lng: "2.352222"
    },
    {
        id: "5",
        branchId: "105",
        email: "david.wilson@example.com",
        firstName: "David",
        lastName: "Wilson",
        phone: "+6677889900",
        role: "Support",
        status: "Inactive",
        gender: "Male",
        dateOfJoin: "2017-09-14",
        dateOfBirth: "1995-12-25",
        designation: "Engineer",
        address: "202 Second St, Hometown, USA",
        lat: "35.689487",
        lng: "139.691706",
        skypeId: "david.wilson"
    }
];

export enum StaffActionsList {
    ADD_STAFF = 'ADD_STAFF',
    EDIT_STAFF = 'EDIT_STAFF',
    FETCH_STAFF = 'FETCH_STAFF',
    DELETE_STAFF = 'DELETE_STAFF',
    EDIT_STATUS = 'EDIT_STATUS'
}

export interface DeleteStaff {
    id: string
}

export interface AddStaffAction {
    [key: string]: any;
    type: StaffActionsList.ADD_STAFF
    data: StaffType
}

export interface EditStaffAction {
    [key: string]: any;
    type: StaffActionsList.EDIT_STAFF
    data: StaffType
    id: string
}

export interface EditStatusAction {
    [key: string]: any;
    type: StaffActionsList.EDIT_STATUS
    data: string
    id: string
}

export interface FetchStaffAction {
    [key: string]: any;
    type: StaffActionsList.FETCH_STAFF
    // data: Staff[]
}

export interface DeleteStaffAction {
    [key: string]: any;
    type: StaffActionsList.DELETE_STAFF
    data: DeleteStaff
}

export type StaffActions = AddStaffAction | EditStaffAction | FetchStaffAction | DeleteStaffAction | EditStatusAction

export const addStaff = (data: StaffType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddStaffAction>({
            type: StaffActionsList.ADD_STAFF,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editStaff = (data: StaffType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditStaffAction>({
            type: StaffActionsList.EDIT_STAFF,
            data: data,
            id: id
        })
    }
}

export const editStatus = (status: string, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditStatusAction>({
            type: StaffActionsList.EDIT_STATUS,
            data: status,
            id: id
        })
    }
}

export const fetchStaff = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchStaffAction>({
            type: StaffActionsList.FETCH_STAFF
            // data: response.data
        })
    }
}

export const deleteStaff = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteStaffAction>({
            type: StaffActionsList.DELETE_STAFF,
            data: { id }
        })
    }
}