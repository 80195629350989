// import Card from '@mui/material/Card';
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import * as Feather from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../components/PageLayout";
import PageTitle from "../components/PageTitle";
import Table from "../components/Table";
import CollapseCard from "../components/custom/CollapseCard";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { addStaff, deleteStaff, editStaff, editStatus, StaffType } from "../redux/actions/staffActions";
import { StoreState } from "../redux/reducers";
import { AppDispatch } from "../redux/store";

const Staff = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const StaffList = useSelector<StoreState, StaffType[]>(state => state.staff)
    console.log(StaffList);

    const [branchId, setBranchId] = useState<string>('');
    const [branchIdError, setBranchIdError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [firstNameError, setFirstNameError] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [roleError, setRoleError] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [statusError, setStatusError] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [genderError, setGenderError] = useState<string>('');
    const [dateOfJoin, setDateOfJoin] = useState<string>('');
    const [dateOfJoinError, setDateOfJoinError] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');
    const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
    const [designation, setDesignation] = useState<string>('');
    const [designationError, setDesignationError] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [addressError, setAddressError] = useState<string>('');
    const [lat, setLat] = useState<string>('');
    const [latError, setLatError] = useState<string>('');
    const [lng, setLng] = useState<string>('');
    const [lngError, setLngError] = useState<string>('');
    const [linkedIn, setLinkedIn] = useState<string>('');
    const [skypeId, setSkypeId] = useState<string>('');

    const [editFlag, setEditFlag] = useState<boolean>(false);
    const [id, setId] = useState<string>('0');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const clearHandler = () => {
        setBranchId('');
        setBranchIdError('');
        setEmail('');
        setEmailError('');
        setFirstName('');
        setFirstNameError('');
        setLastName('');
        setLastNameError('');
        setPhone('');
        setPhoneError('');
        setRole('');
        setRoleError('');
        setStatus('');
        setStatusError('');
        setGender('');
        setGenderError('');
        setDateOfJoin('');
        setDateOfJoinError('');
        setDateOfBirth('');
        setDateOfBirthError('');
        setDesignation('');
        setDesignationError('');
        setAddress('');
        setAddressError('');
        setLat('');
        setLatError('');
        setLng('');
        setLngError('');
        setLinkedIn('');
        setSkypeId('');
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        // Handle different types of input fields
        switch (name) {
            case 'branchId':
                setBranchId(value);
                setBranchIdError('');
                break;
            case 'email':
                setEmail(value);
                setEmailError('');
                break;
            case 'firstName':
                setFirstName(value);
                setFirstNameError('');
                break;
            case 'lastName':
                setLastName(value);
                setLastNameError('');
                break;
            case 'phone':
                setPhone(value);
                setPhoneError('');
                break;
            case 'role':
                setRole(value);
                setRoleError('');
                break;
            case 'status':
                setStatus(value);
                setStatusError('');
                break;
            case 'gender':
                setGender(value);
                setGenderError('');
                break;
            case 'dateOfJoin':
                setDateOfJoin(value);
                setDateOfJoinError('');
                break;
            case 'dateOfBirth':
                setDateOfBirth(value);
                setDateOfBirthError('');
                break;
            case 'designation':
                setDesignation(value);
                setDesignationError('');
                break;
            case 'address':
                setAddress(value);
                setAddressError('');
                break;
            case 'lat':
                setLat(value);
                setLatError('');
                break;
            case 'lng':
                setLng(value);
                setLngError('');
                break;
            case 'linkedIn':
                setLinkedIn(value);
                break;
            case 'skypeId':
                setSkypeId(value);
                break;
            default:
                break;
        }
    };

    //add hall
    const addHandler = () => {
        let error = false
        if (firstName.trim() === '') {
            setFirstNameError('First Name is required');
            error = true;
        } else if (firstName.length > 50) {
            setFirstNameError('First Name must be within 50 characters');
            error = true;
        }

        if (lastName.trim() === '') {
            setLastNameError('Last Name is required');
            error = true;
        } else if (lastName.length > 50) {
            setLastNameError('Last Name must be within 50 characters');
            error = true;
        }

        if (address.trim() === '') {
            setAddressError('Address is required');
            error = true;
        }

        if (phone.trim() === '') {
            setPhoneError('Phone is required');
            error = true;
        }

        if (email.trim() === '') {
            setEmailError('Email is required');
            error = true;
        }

        if (status.trim() === '') {
            setStatusError('Status is required');
            error = true;
        }

        if (gender.trim() === '') {
            setGenderError('Gender is required');
            error = true;
        }

        if (dateOfJoin.trim() === '') {
            setDateOfJoinError('Date of Join is required');
            error = true;
        }

        if (dateOfBirth.trim() === '') {
            setDateOfBirthError('Date of Birth is required');
            error = true;
        }

        if (designation.trim() === '') {
            setDesignationError('Designation is required');
            error = true;
        }

        if (branchId.trim() === '') {
            setBranchIdError('Branch ID is required');
            error = true;
        }

        const addData: StaffType = {
            branchId: branchId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            role: role,
            status: status,
            gender: gender,
            dateOfJoin: dateOfJoin,
            dateOfBirth: dateOfBirth,
            designation: designation,
            address: address,
            lat: lat,
            lng: lng,
            linkedIn: linkedIn,
            skypeId: skypeId
        };

        if (!error) {
            dispatch(addStaff(addData)).then((text) => {
                toast('Staff Added', ToastTypes.SUCCESS);
                clearHandler();
            }).catch((text) => {
                toast(text, ToastTypes.ERROR);
            });
        }
    }

    const getStatusById = (val: any) => {
        let obj = StaffList.find(f => f.id === val.id)
        let status = obj?.status === 'Active' ? true : false
        return status
    }

    const updateStatusHandler = (val: any) => {
        if (val.id) {
            let obj = StaffList.find(f => f.id === val.id)
            let status = obj?.status === 'Active' ? 'Inactive' : 'Active'

            dispatch(editStatus(status, val.id)).then(text => {
                // toast('Status Updated', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const editHandler = (staff: any) => {
        console.log('edit');
        console.log(staff);
        let obj = StaffList.find(f => f.id === staff.id)
        setEditFlag(true);
        setId(staff.id ? staff.id : '0');
        setBranchId(staff.branchId);
        setEmail(staff.email);
        setFirstName(staff.firstName);
        setLastName(staff.lastName);
        setPhone(staff.phone);
        setRole(staff.role);
        setStatus(obj?.status || '');
        setGender(staff.gender);
        setDateOfJoin(staff.dateOfJoin);
        setDateOfBirth(staff.dateOfBirth);
        setDesignation(staff.designation);
        setAddress(staff.address);
        setLat(staff.lat);
        setLng(staff.lng);
        setLinkedIn(staff.linkedIn || '');
        setSkypeId(staff.skypeId || '');
    }

    const updateHandler = () => {
        let error = false
        if (firstName.trim() === '') {
            setFirstNameError('First Name is required');
            error = true;
        } else if (firstName.length > 50) {
            setFirstNameError('First Name must be within 50 characters');
            error = true;
        }

        if (lastName.trim() === '') {
            setLastNameError('Last Name is required');
            error = true;
        } else if (lastName.length > 50) {
            setLastNameError('Last Name must be within 50 characters');
            error = true;
        }

        if (address.trim() === '') {
            setAddressError('Address is required');
            error = true;
        }

        if (phone.trim() === '') {
            setPhoneError('Phone is required');
            error = true;
        }

        if (email.trim() === '') {
            setEmailError('Email is required');
            error = true;
        }

        if (status.trim() === '') {
            setStatusError('Status is required');
            error = true;
        }

        if (gender.trim() === '') {
            setGenderError('Gender is required');
            error = true;
        }

        if (dateOfJoin.trim() === '') {
            setDateOfJoinError('Date of Join is required');
            error = true;
        }

        if (dateOfBirth.trim() === '') {
            setDateOfBirthError('Date of Birth is required');
            error = true;
        }

        if (designation.trim() === '') {
            setDesignationError('Designation is required');
            error = true;
        }

        if (branchId.trim() === '') {
            setBranchIdError('Branch ID is required');
            error = true;
        }
        console.log(id);

        const editData = {
            id: id,
            branchId: branchId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            role: role,
            status: status,
            gender: gender,
            dateOfJoin: dateOfJoin,
            dateOfBirth: dateOfBirth,
            designation: designation,
            address: address,
            lat: lat,
            lng: lng,
            linkedIn: linkedIn,
            skypeId: skypeId
        };
        if (!error) {
            dispatch(editStaff(editData, id)).then(text => {
                toast('Staff Updated', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })

        }
    }

    const deleteHandler = (id: string) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteStaff(id)).then(text => {
                toast('Staff Deleted', ToastTypes.SUCCESS)
                closeDeleteConfirmation()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const head = [
        { field: 'id', headerName: 'S.No', width: 130, headerClassName: 'bg-secondary text-white' },
        { field: 'firstName', headerName: 'First Name', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'lastName', headerName: 'Last Name', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'phone', headerName: 'Phone', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'role', headerName: 'Role', width: 150, headerClassName: 'bg-secondary text-white' },
        {
            field: 'status', headerName: 'Status', width: 150, headerClassName: 'bg-secondary text-white', renderCell: (params: any) => (
                < div className='form-check form-switch m-1' >
                    <input className='form-check-input' type="checkbox" checked={getStatusById(params.row)} onClick={() => { updateStatusHandler(params.row) }}></input>
                </div >
            )
        },
        { field: 'designation', headerName: 'Designation', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'email', headerName: 'Email', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'dateOfJoin', headerName: 'Date of Join', width: 180, headerClassName: 'bg-secondary text-white' },
        { field: 'dateOfBirth', headerName: 'Date of Birth', width: 180, headerClassName: 'bg-secondary text-white' },
        { field: 'gender', headerName: 'Gender', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'branchId', headerName: 'Branch ID', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'address', headerName: 'Address', width: 180, headerClassName: 'bg-secondary text-white' },
        { field: 'lat', headerName: 'Latitude', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'lng', headerName: 'Longitude', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'linkedIn', headerName: 'LinkedIn', width: 180, headerClassName: 'bg-secondary text-white' },
        { field: 'skypeId', headerName: 'Skype ID', width: 150, headerClassName: 'bg-secondary text-white' },
        {
            field: 'Action', headerName: 'Action', width: 150, headerClassName: 'bg-secondary text-white', renderCell: (params: any) => (
                <div className='hstack gap-1 m-2'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(params.row)}><Feather.Edit size={20} /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(params.row.id)}><Feather.Trash size={20} /></button>
                </div>
            )
        },
    ];


    const body: any[] = []

    //table
    StaffList.forEach((e, i) => {
        body.push({
            id: (i + 1).toString(),
            firstName: e.firstName,
            lastName: e.lastName,
            phone: e.phone,
            role: e.role,
            status: (<div className='form-check form-switch m-1'>
                <input className='form-check-input' type="checkbox" checked={e.status === 'Active' ? true : false} onClick={() => updateStatusHandler(e)}></input>
            </div>),
            designation: e.designation,
            email: e.email,
            dateOfJoin: e.dateOfJoin,
            dateOfBirth: e.dateOfBirth,
            gender: e.gender,
            branchId: e.branchId,
            address: e.address,
            lat: e.lat,
            lng: e.lng,
            linkedIn: e.linkedIn,
            skypeId: e.skypeId,
            Action: (
                <div className='hstack gap-1'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(e)}> <Feather.Edit /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(e.id!)}> <Feather.Delete /></button>
                </div>
            )
        })
    })


    // const csvData = [
    //     [
    //         'S.No',
    //         'Name',
    //         'Address',
    //         "Phone",
    //         'Phone 2',
    //         'Tag',
    //         'Status',
    //         'Assigned To',
    //         "Position",
    //         "Email",
    //         'City',
    //         'State',
    //         'Country',
    //         'Zipcode',
    //         'Description'
    //     ]
    // ]

    // //csv
    // StaffList.forEach((e, i) => {
    //     csvData.push([(i + 1).toString(), e.name, e.address, e.phone, e.phone2?.toString(), e.tag, e.status, e.assignedTo, e.position, e.email, e.city, e.state, e.country, e.zipcode?.toString(), e.description])
    // })

    return <>
        <PageLayout>
            <div>
                <PageTitle
                    title="Staff"
                    buttonText=""
                />
                <Card className="m-3" title={editFlag ? 'Edit Staff' : 'Add Staff'}>
                    <div className='container m-0'>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">First Name <span className="text-danger">*</span> :</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter First Name..."
                                    type="text"
                                    name="firstName"
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        setFirstNameError('');
                                    }}
                                    value={firstName}
                                />
                                {firstNameError && <div className="text-danger">{firstNameError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Last Name <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Last Name..."
                                    type="text"
                                    name="lastName"
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        setLastNameError('');
                                    }}
                                    value={lastName}
                                />
                                {lastNameError && <div className="text-danger">{lastNameError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Phone <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Phone..."
                                    type="text"
                                    name="phone"
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                        setPhoneError('');
                                    }}
                                    value={phone}
                                />
                                {phoneError && <div className="text-danger">{phoneError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Role <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Role..."
                                    type="text"
                                    name="role"
                                    onChange={(e) => {
                                        setRole(e.target.value);
                                        setRoleError('');
                                    }}
                                    value={role}
                                />
                                {roleError && <div className="text-danger">{roleError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Status <span className="text-danger">*</span>:</label>
                                {/* <input
                                    className="form-control"
                                    placeholder="Enter Status..."
                                    type="text"
                                    name="status"
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                        setStatusError('');
                                    }}
                                    value={status}
                                /> */}
                                <select
                                    className="form-control"
                                    name="status"
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                        setStatusError('');
                                    }}
                                    value={status}
                                >
                                    <option value="">Select Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                {statusError && <div className="text-danger">{statusError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Gender <span className="text-danger">*</span>:</label>
                                <select
                                    className="form-control"
                                    name="gender"
                                    onChange={(e) => {
                                        setGender(e.target.value);
                                        setGenderError('');
                                    }}
                                    value={gender}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                                {genderError && <div className="text-danger">{genderError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Date of Join <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="dateOfJoin"
                                    onChange={(e) => {
                                        setDateOfJoin(e.target.value);
                                        setDateOfJoinError('');
                                    }}
                                    value={dateOfJoin}
                                />
                                {dateOfJoinError && <div className="text-danger">{dateOfJoinError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Date of Birth <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="dateOfBirth"
                                    onChange={(e) => {
                                        setDateOfBirth(e.target.value);
                                        setDateOfBirthError('');
                                    }}
                                    value={dateOfBirth}
                                />
                                {dateOfBirthError && <div className="text-danger">{dateOfBirthError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Designation <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Designation..."
                                    type="text"
                                    name="designation"
                                    onChange={(e) => {
                                        setDesignation(e.target.value);
                                        setDesignationError('');
                                    }}
                                    value={designation}
                                />
                                {designationError && <div className="text-danger">{designationError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Email <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Email..."
                                    type="email"
                                    name="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError('');
                                    }}
                                    value={email}
                                />
                                {emailError && <div className="text-danger">{emailError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Branch ID <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Branch ID..."
                                    type="text"
                                    name="branchId"
                                    onChange={(e) => {
                                        setBranchId(e.target.value);
                                        setBranchIdError('');
                                    }}
                                    value={branchId}
                                />
                                {branchIdError && <div className="text-danger">{branchIdError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Address <span className="text-danger">*</span>:</label>
                                <textarea
                                    className="form-control"
                                    placeholder="Enter Address..."
                                    name="address"
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                        setAddressError('');
                                    }}
                                    value={address}
                                />
                                {addressError && <div className="text-danger">{addressError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Latitude <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Latitude..."
                                    type="text"
                                    name="lat"
                                    onChange={(e) => {
                                        setLat(e.target.value);
                                        setLatError('');
                                    }}
                                    value={lat}
                                />
                                {latError && <div className="text-danger">{latError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Longitude <span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    placeholder="Enter Longitude..."
                                    type="text"
                                    name="lng"
                                    onChange={(e) => {
                                        setLng(e.target.value);
                                        setLngError('');
                                    }}
                                    value={lng}
                                />
                                {lngError && <div className="text-danger">{lngError}</div>}
                            </div>
                        </div>


                        <div className="col m-4 d-flex justify-content-end">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>

                    </div>
                </Card>
                <CollapseCard title="Staff">
                    <div className="container-fluid p-2" style={{ width: "100%" }}>
                        <Table
                            head={head}
                            body={body}
                        />
                        {/* <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Seating Capacity</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hallList.map((hl, index) => (
                                    <tr key={hl.id}>
                                        <td>{index + 1}</td>
                                        <td>{hl.name}</td>
                                        <td>{hl.price}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(hl)}>Edit</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(hl.id ? hl.id : 0)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                    <div className="text-end mb-3">
                        {/* {examTimetableData.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>} */}
                        {/* {StaffList.length > 0 && (<CSVLink
                            data={csvData}
                            filename="exam_timetable_report.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>)} */}
                    </div>
                </CollapseCard>
                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </PageLayout>
    </>
}
export default Staff