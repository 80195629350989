import { ReactNode, Suspense, useState } from "react"
import SideMenuBar from "./SideMenuBar"
import TopAppBar from "./TopAppBar"
import Footer from "./custom/Footer"
interface props {
    children: ReactNode
}
const PageLayout = (props: props) => {
    const [showSideMenu, setShowSideMenu] = useState(true);

    const handleMenuButtonClick = () => {
        setShowSideMenu(!showSideMenu)
    }

    return <>
        <TopAppBar onMenuButtonClick={handleMenuButtonClick} />
        <SideMenuBar showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} onMenuButtonClick={handleMenuButtonClick} />
        <div className={`content-page ${!showSideMenu ? 'mx-0 container-fluid mx-auto' : ''}`}>
            <div className="content">
                <div className="container-fluid">
                    <Suspense>{props.children}</Suspense>
                </div>
            </div>

            <Footer />
        </div>
    </>
}
export default PageLayout