// import Card from '@mui/material/Card';
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import * as Feather from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../components/PageLayout";
import PageTitle from "../components/PageTitle";
import Table from "../components/Table";
import CollapseCard from "../components/custom/CollapseCard";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { LeadType, addLead, deleteLead, editLead, editStatus } from "../redux/actions/leadActions";
import { StoreState } from "../redux/reducers";
import { AppDispatch } from "../redux/store";

const Lead = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const LeadList = useSelector<StoreState, LeadType[]>(state => state.lead)

    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');

    const [address, setAddress] = useState<string>('');
    const [addressError, setAddressError] = useState<string>('');

    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');

    const [phone2, setPhone2] = useState<string>('');
    const [phone2Error, setPhone2Error] = useState<string>('');

    const [tag, setTag] = useState<string>('');
    const [tagError, setTagError] = useState<string>('');

    const [status, setStatus] = useState<string>('');
    const [statusError, setStatusError] = useState<string>('');

    const [assignedTo, setAssignedTo] = useState<string>('');
    const [assignedToError, setAssignedToError] = useState<string>('');

    const [position, setPosition] = useState<string>('');
    const [positionError, setPositionError] = useState<string>('');

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');

    const [city, setCity] = useState<string>('');
    const [cityError, setCityError] = useState<string>('');

    const [state, setState] = useState<string>('');
    const [stateError, setStateError] = useState<string>('');

    const [country, setCountry] = useState<string>('');
    const [countryError, setCountryError] = useState<string>('');

    const [zipcode, setZipcode] = useState<number>(0);
    const [zipcodeError, setZipcodeError] = useState<string>('');

    const [description, setDescription] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState<string>('');

    const [editFlag, setEditFlag] = useState<boolean>(false);
    const [id, setId] = useState<string>('0');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const clearHandler = () => {
        setName('');
        setNameError('');
        setAddress('');
        setAddressError('');
        setPhone('');
        setPhoneError('');
        setPhone2('');
        setPhone2Error('');
        setTag('');
        setTagError('');
        setStatus('');
        setStatusError('');
        setAssignedTo('');
        setAssignedToError('');
        setPosition('');
        setPositionError('');
        setEmail('');
        setEmailError('');
        setCity('');
        setCityError('');
        setState('');
        setStateError('');
        setCountry('');
        setCountryError('');
        setZipcode(0);
        setZipcodeError('');
        setDescription('');
        setDescriptionError('');
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        // Handle different types of input fields
        switch (name) {
            case 'name':
                setName(value);
                setNameError('');
                break;
            case 'address':
                setAddress(value);
                setAddressError('');
                break;
            case 'phone':
                setPhone(value);
                setPhoneError('');
                break;
            case 'phone2':
                setPhone2(value);
                setPhone2Error('');
                break;
            case 'tag':
                setTag(value);
                setTagError('');
                break;
            case 'status':
                setStatus(value);
                setStatusError('');
                break;
            case 'assignedTo':
                setAssignedTo(value);
                setAssignedToError('');
                break;
            case 'position':
                setPosition(value);
                setPositionError('');
                break;
            case 'email':
                setEmail(value);
                setEmailError('');
                break;
            case 'city':
                setCity(value);
                setCityError('');
                break;
            case 'state':
                setState(value);
                setStateError('');
                break;
            case 'country':
                setCountry(value);
                setCountryError('');
                break;
            case 'zipcode':
                setZipcode(parseInt(value));
                setZipcodeError('');
                break;
            case 'description':
                setDescription(value);
                setDescriptionError('');
                break;
            default:
                break;
        }
    };

    //add hall
    const addHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true
        }
        if (address.trim() === '') {
            setAddressError('Address is required');
            error = true
        }

        if (phone.trim() === '') {
            setPhoneError('Phone is required');
            error = true
        }

        if (phone2.trim() === '') {
            setPhone2Error('Phone 2 is required');
            error = true
        }

        if (tag.trim() === '') {
            setTagError('Tag is required');
            error = true
        }

        if (status.trim() === '') {
            setStatusError('Status is required');
            error = true
        }

        if (assignedTo.trim() === '') {
            setAssignedToError('Assigned To is required');
            error = true
        }

        if (position.trim() === '') {
            setPositionError('Position is required');
            error = true
        }

        if (email.trim() === '') {
            setEmailError('Email is required');
            error = true
        }

        if (city.trim() === '') {
            setCityError('City is required');
            error = true
        }

        if (state.trim() === '') {
            setStateError('State is required');
            error = true
        }

        if (country.trim() === '') {
            setCountryError('Country is required');
            error = true
        }

        if (zipcode === 0) {
            setZipcodeError('Zipcode is required');
            error = true
        }

        if (description.trim() === '') {
            setDescriptionError('Description is required');
            error = true
        }

        const addData = {
            name: name,
            address: address,
            phone: phone,
            phone2: phone2,
            tag: tag,
            status: status,
            assignedTo: assignedTo,
            position: position,
            email: email,
            city: city,
            state: state,
            country: country,
            zipcode: zipcode,
            description: description,
        };

        if (!error) {
            dispatch(addLead(addData)).then((text) => {
                toast('Lead Added', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const getStatusById = (val: any) => {
        let obj = LeadList.find(f => f.id === val.id)
        let status = obj?.status === 'Active' ? true : false
        return status
    }

    const updateStatusHandler = (val: any) => {
        if (val.id) {
            let obj = LeadList.find(f => f.id === val.id)
            let status = obj?.status === 'Active' ? 'Inactive' : 'Active'

            dispatch(editStatus(status, val.id)).then(text => {
                // toast('Status Updated', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const editHandler = (hl: any) => {
        console.log('edit');
        console.log(hl);
        let obj = LeadList.find(f => f.id === hl.id)

        setEditFlag(true);
        setId(hl.id ? hl.id : '0');
        setName(hl.Name);
        setAddress(hl.Address);
        setPhone(hl.Phone);
        setPhone2(hl.SecondaryContactNo);
        setTag(hl.Tag);
        setStatus(obj?.status || '');
        setAssignedTo(hl.AssignedTo);
        setPosition(hl.Position);
        setEmail(hl.Email);
        setCity(hl.City);
        setState(hl.State);
        setCountry(hl.Country);
        setZipcode(hl.Zipcode);
        setDescription(hl.Description);
    }

    const updateHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Name is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true
        }
        if (address.trim() === '') {
            setAddressError('Address is required');
            error = true
        }

        if (phone.trim() === '') {
            setPhoneError('Phone is required');
            error = true
        }

        if (phone2.trim() === '') {
            setPhone2Error('Phone 2 is required');
            error = true
        }

        if (tag.trim() === '') {
            setTagError('Tag is required');
            error = true
        }

        if (status.trim() === '') {
            setStatusError('Status is required');
            error = true
        }

        if (assignedTo.trim() === '') {
            setAssignedToError('Assigned To is required');
            error = true
        }

        if (position.trim() === '') {
            setPositionError('Position is required');
            error = true
        }

        if (email.trim() === '') {
            setEmailError('Email is required');
            error = true
        }

        if (city.trim() === '') {
            setCityError('City is required');
            error = true
        }

        if (state.trim() === '') {
            setStateError('State is required');
            error = true
        }

        if (country.trim() === '') {
            setCountryError('Country is required');
            error = true
        }

        if (zipcode === 0) {
            setZipcodeError('Zipcode is required');
            error = true
        }

        if (description.trim() === '') {
            setDescriptionError('Description is required');
            error = true
        }
        console.log(id);
        console.log(country);

        const editData = {
            id: id,
            name: name,
            address: address,
            phone: phone,
            phone2: phone2,
            tag: tag,
            status: status,
            assignedTo: assignedTo,
            position: position,
            email: email,
            city: city,
            state: state,
            country: country,
            zipcode: zipcode,
            description: description,
        };
        if (!error) {
            dispatch(editLead(editData, id)).then(text => {
                toast('Lead Updated', ToastTypes.SUCCESS)
                clearHandler()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })

        }
    }

    const deleteHandler = (id: string) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteLead(id)).then(text => {
                toast('Lead Deleted', ToastTypes.SUCCESS)
                closeDeleteConfirmation()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const head = [
        { field: 'id', headerName: 'S.No', width: 130, headerClassName: 'bg-secondary text-white' },
        { field: 'Name', headerName: 'Name', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'Address', headerName: 'Address', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'Phone', headerName: 'Phone', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'SecondaryContactNo', headerName: 'Secondary.Cont.No', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'Tag', headerName: 'Tag', width: 150, headerClassName: 'bg-secondary text-white' },
        {
            field: 'Status', headerName: 'Status', width: 150, headerClassName: 'bg-secondary text-white', renderCell: (params: any) => (
                < div className='form-check form-switch m-1' >
                    <input className='form-check-input' type="checkbox" checked={getStatusById(params.row)} onClick={() => { updateStatusHandler(params.row) }}></input>
                </div >
            )
        },
        { field: 'AssignedTo', headerName: 'Assigned To', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'Position', headerName: 'Position', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'Email', headerName: 'Email', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'City', headerName: 'City', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'State', headerName: 'State', width: 150, headerClassName: 'bg-secondary text-white' },
        { field: 'Country', headerName: 'Country', width: 180, headerClassName: 'bg-secondary text-white' },
        { field: 'Zipcode', headerName: 'Zipcode', width: 180, headerClassName: 'bg-secondary text-white' },
        { field: 'Description', headerName: 'Description', width: 180, headerClassName: 'bg-secondary text-white' },
        {
            field: 'Action', headerName: 'Action', width: 150, headerClassName: 'bg-secondary text-white', renderCell: (params: any) => (
                <div className='hstack gap-1 m-2'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(params.row)}><Feather.Edit size={20} /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(params.row.id)}><Feather.Trash size={20} /></button>
                </div>
            ),
        },
    ]

    const body: any[] = []

    //table
    LeadList.forEach((e, i) => {
        body.push({
            id: (i + 1).toString(),
            Name: e.name,
            Address: e.address,
            Phone: e.phone,
            SecondaryContactNo: e.phone2,
            Tag: e.tag,
            Status: (<div className='form-check form-switch m-1'>
                <input className='form-check-input' type="checkbox" checked={e.status === 'Active' ? true : false} onClick={() => updateStatusHandler(e)}></input>
            </div>),
            AssignedTo: e.assignedTo,
            Position: e.position,
            Email: e.email,
            City: e.city,
            State: e.state,
            Country: e.country,
            Zipcode: e.zipcode,
            Description: e.description,
            Action: (
                <div className='hstack gap-1'>
                    <button className='btn btn-sm btn-primary' onClick={() => editHandler(e)}> <Feather.Edit /></button>
                    <button className='btn btn-sm btn-danger' onClick={() => deleteHandler(e.id!)}> <Feather.Delete /></button>
                </div>
            )
        })
    })

    const csvData = [
        [
            'S.No',
            'Name',
            'Address',
            "Phone",
            'Phone 2',
            'Tag',
            'Status',
            'Assigned To',
            "Position",
            "Email",
            'City',
            'State',
            'Country',
            'Zipcode',
            'Description'
        ]
    ]

    //csv
    LeadList.forEach((e, i) => {
        csvData.push([(i + 1).toString(), e.name, e.address, e.phone, e.phone2?.toString(), e.tag, e.status, e.assignedTo, e.position, e.email, e.city, e.state, e.country, e.zipcode?.toString(), e.description])
    })

    return <>
        <PageLayout>
            <div>
                <PageTitle
                    title="Lead"
                    buttonText=""
                />
                <Card className="m-3" title={editFlag ? 'Edit Lead' : 'Add Lead'}>
                    <div className='container m-0'>
                        <div className="row">
                            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                                <FormInput
                                    label='Name'
                                    name=""
                                    labelClassName="required"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setNameError('')
                                    }}
                                    placeholder='Name'
                                    errorText={nameError}
                                    containerClass="mb-2"
                                />
                            </div> */}
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                                <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                    setName(e.target.value)
                                    setNameError('')
                                }} value={name} />
                                {nameError && <div className="text-danger">{nameError}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Phone<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter Phone..."
                                    type="number"
                                    name="phone"
                                    value={phone}
                                    onChange={handleInputChange}
                                />
                                {phoneError && <span className="text-danger">{phoneError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Phone 2<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter Phone 2..."
                                    type="number"
                                    name="phone2"
                                    value={phone2}
                                    onChange={handleInputChange}
                                />
                                {phone2Error && <span className="text-danger">{phone2Error}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Tag<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="tag"
                                    value={tag}
                                    onChange={handleInputChange}
                                />
                                {tagError && <span className="text-danger">{tagError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Status<span className="text-danger">*</span>:</label>
                                <select
                                    className="form-control"
                                    name="status"
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                        setStatusError('');
                                    }}
                                    value={status}
                                >
                                    <option value="">Select Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                {statusError && <span className="text-danger">{statusError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Assigned To<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder=""
                                    type="text"
                                    name="assignedTo"
                                    value={assignedTo}
                                    onChange={handleInputChange}
                                />
                                {assignedToError && <span className="text-danger">{assignedToError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Position<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter Position..."
                                    type="text"
                                    name="position"
                                    value={position}
                                    onChange={handleInputChange}
                                />
                                {positionError && <span className="text-danger">{positionError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Email<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter Email..."
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleInputChange}
                                />
                                {emailError && <span className="text-danger">{emailError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">City<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter City..."
                                    type="text"
                                    name="city"
                                    value={city}
                                    onChange={handleInputChange}
                                />
                                {cityError && <span className="text-danger">{cityError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">State<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter State..."
                                    type="text"
                                    name="state"
                                    value={state}
                                    onChange={handleInputChange}
                                />
                                {stateError && <span className="text-danger">{stateError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Country<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter Country..."
                                    type="text"
                                    name="country"
                                    value={country}
                                    onChange={handleInputChange}
                                />
                                {countryError && <span className="text-danger">{countryError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Zipcode<span className="text-danger">*</span>:</label>
                                <input
                                    className="form-control" placeholder="Enter Zipcode..."
                                    type="number"
                                    name="zipcode"
                                    value={zipcode === 0 ? '' : zipcode}
                                    onChange={handleInputChange}
                                />
                                {zipcodeError && <span className="text-danger">{zipcodeError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Address<span className="text-danger">*</span>:</label>
                                <textarea
                                    className="form-control" placeholder="Enter Address..."
                                    name="address"
                                    value={address}
                                    onChange={handleInputChange}
                                />
                                {addressError && <span className="text-danger">{addressError}</span>}
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-4">
                                <label className="mt-3 mb-3">Description<span className="text-danger">*</span>:</label>
                                <textarea
                                    className="form-control" placeholder="Enter Description..."
                                    name="description"
                                    value={description}
                                    onChange={handleInputChange}
                                />
                                {descriptionError && <span className="text-danger">{descriptionError}</span>}
                            </div>

                        </div>

                        <div className="col m-4 d-flex justify-content-end">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>

                    </div>
                </Card>
                <CollapseCard title="Lead">
                    <div className="container-fluid p-2" style={{ width: "100%" }}>
                        <Table
                            head={head}
                            body={body}
                        />
                        {/* <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Seating Capacity</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hallList.map((hl, index) => (
                                    <tr key={hl.id}>
                                        <td>{index + 1}</td>
                                        <td>{hl.name}</td>
                                        <td>{hl.price}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(hl)}>Edit</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(hl.id ? hl.id : 0)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                    <div className="text-end mb-3">
                        {/* {examTimetableData.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>} */}
                        {/* {LeadList.length > 0 && (<CSVLink
                            data={csvData}
                            filename="exam_timetable_report.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>)} */}
                    </div>
                </CollapseCard>
                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </PageLayout>
    </>
}
export default Lead